import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { AdaptivityProvider, ConfigProvider } from "@vkontakte/vkui";
import App from "./App";

ReactDOM.render(
	<ConfigProvider>
		<AdaptivityProvider>
			<App />
		</AdaptivityProvider>
	</ConfigProvider>,
	document.getElementById("root")
);
// if (process.env.NODE_ENV === "development") {
//   import("./eruda").then(({ default: eruda }) => {}); //runtime download
// }
