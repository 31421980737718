import React from "react";
import ReactGA from "react-ga";
import "@vkontakte/vkui/dist/vkui.css";
import {
	View,
	Panel,
	PanelHeader,
	Div,
	Textarea,
	FormItem,
	Button,
	Spacing,
	AppRoot,
	Alert,
	Checkbox,
	ModalRoot,
	ModalPage,
	ModalPageHeader,
	PanelHeaderButton,
	PanelHeaderClose,
	useAdaptivity,
	ScreenSpinner,
	ANDROID,
	IOS,
	Text,
	Title,
	ViewWidth,
	usePlatform,
} from "@vkontakte/vkui";
import { Icon24Dismiss, Icon24Share, Icon36LogoVk } from "@vkontakte/icons";
import {
	FacebookShareButton,
	VKShareButton,
	OKShareButton,
	FacebookIcon,
	VKIcon,
	OKIcon,
} from "react-share";
import bridge from "@vkontakte/vk-bridge";
import VKAuth from "./VKAuth/VKAuth";

// MODAL WINDOW
const DynamicModalPageRules = ({ updateModalHeight, onClose, ...props }) => {
	const { viewWidth } = useAdaptivity();
	const isMobile = viewWidth <= ViewWidth.MOBILE;
	const platform = usePlatform();

	return (
		<ModalPage
			{...props}
			header={
				<ModalPageHeader
					right={
						isMobile &&
						platform === IOS && (
							<PanelHeaderButton onClick={onClose}>
								<Icon24Dismiss />
							</PanelHeaderButton>
						)
					}
					left={
						isMobile &&
						platform === ANDROID && <PanelHeaderClose onClick={onClose} />
					}>
					Правила конкурса
				</ModalPageHeader>
			}>
			<Div>
				<Title level="3" weight="semibold">
					Правила проведения акции «Сказки О!» (далее - «Правила»)
				</Title>
				<Text weight="regular">
					<p>
						1. Общие положения: Акция «Сказки О!» проводится АО «Первый канал.
						Всемирная сеть» (ОГРН 1027739549221, ИНН 7717029599, адрес: 127427,
						Российская Федерация, г. Москва, Академика Королева,19) (далее —
						«Организатор»).
					</p>
					<p>
						1.1. Акция проводится в целях реализации творческих способностей
						зрителей телеканала, привлечения новых пользователей сайта и
						увеличения числа зрителей детско-юношеского телеканала «О!»
						(свидетельство о регистрации СМИ Эл № ФС77-67761 от 17.11.2016).
					</p>
					<p>
						1.2. Акция является открытой и проводится на территории Российской
						Федерации в соответствии с Правилами, а также действующим
						законодательством России (далее — «РФ») в срок с 01 июня 2021 года
						по 31 августа 2021 года.
					</p>
					<p>
						1.3. Участниками Акции могут стать граждане Российской Федерации в
						возрасте от 18 лет, выполнившие условия, установленные в Правилах
						(далее — «Участник»).
					</p>
					<p>1.4. Участие в Акции является бесплатным.</p>
					<p>
						1.5. Порядок организации и проведения Акции определен в Правилах
						проведения Акции. Правила проведения Акции размещаются на
						Интернет-сайте телеканала «О!» www.kanal-o.ru. На указанном сайте
						также содержится дополнительная информация о проводимой Акции. Все
						изменения, вносимые в Правила проведения Акции, а также иная
						информация для Участников Акции размещаются на указанном сайте.
					</p>
					<p>
						1.6. Правила могут быть изменены и/или дополнены Организатором в
						одностороннем порядке без специального уведомления.
					</p>
					<p>2. Порядок проведения Акции:</p>
					<p>2.1. Акция состоит из творческого конкурса.</p>
					<p>
						2.1.1. В период с 01 июня 2021 года по 31 августа 2021 в
						соответствии с заданием Участнику необходимо создать творческую
						работу (далее по отдельности «Работа», а совместно — «Работы»).
						Работа представляет собой текст.
					</p>
					<p>
						2.1.2. В период с 01 июня 2021 года по 31 августа 2021 года в
						объявленный срок конкурса до 23:59 московского времени
						осуществляется приём Работ. 1 сентября 2021 года осуществляется
						оценка Работ жюри и публикация результатов.
					</p>
					<p>
						2.2. Принимая участие в Акции, Участник дает согласие на
						использование своих Работ в соответствии с разделом 4 Правил.
					</p>
					<p>
						2.3. Если в ходе проверки выяснилось, что Участник указал
						недостоверную информацию о себе, Организатор оставляет за собой
						право объявить победителем другого Участника.
					</p>
					<p>
						2.4. Победителями Акции (далее — «Победители») становятся Участники
						— авторы лучших Работ по мнению экспертного жюри. В состав
						экспертного жюри входят представители редакции телеканала «О!»,
						которые назначаются Организатором Акции по своему усмотрению.
						Победители определяются голосованием экспертного жюри в сроки,
						указанные в п. 2.1.2. и 2.1.3. правил Акции.
					</p>
					<p>
						Экспертное жюри руководствуется следующими критериями при выборе
						лучших Работ:
					</p>
					<ul>
						<li>оригинальность;</li>
						<li>художественная выразительность.</li>
					</ul>
					<p>
						2.5. Десять Победителей будут определены по результатам Акции.
						Работы, отправленные Победителями Акции в порядке, определённом
						далее в разделе 3, будут размещены на сайте телеканала «О!»
						www.kanal-o.ru, а также в официальных аккаунтах телеканала «О!» в
						социальных сетях, включая, но не ограничиваясь, в социальных сетях
						«Одноклассники» (ok.ru), «Вконтакте» (vk.com), «Фейсбук»
						(facebook.com), «Инстаграм» (Instagram.com).
					</p>
					<p>
						2.6. Организатор вправе вручать призы Победителям Акции. Каждый
						Победитель получает приз в зависимости от конкурса Акции. Количество
						и характер призов определяются Организатором по своему усмотрению и
						указываются в анонсе Акции. Порядок вручения таких призов
						согласуется Организатором с Победителями в рабочем порядке по
						электронной почте и/или по телефону, а также путем обмена
						сообщениями в социальных сетях. Все призы Победителям Акции
						отправляются через курьерскую службу в срок до 1 октября 2021 года.
						Организатор не осуществляет повторную отправку призов в случае
						неверно указанных Участником данных или в случае отсутствия
						Участника по указанному адресу. Победитель имеет право распорядиться
						призом по своему усмотрению, в том числе передать приз третьему
						лицу.
					</p>
					<p>
						В случае если Участник не ответит Организатору в течение суток после
						объявления Победителя соответствующего конкурса Акции, после
						отправки личного сообщения/ электронного письма/ осуществления
						звонка Организатором, Организатор имеет право распорядиться призом
						по своему усмотрению, в том числе передать приз другому Участнику
						Акции.
					</p>
					<p>
						Стоимость каждого из призов не превышает 4 000 (четыре тысячи)
						рублей. Организатор не несёт ответственность за целостность и
						качество призов. Претензии относительно призов должны предъявляться
						непосредственно их производителям. В случае если стоимость
						какого-либо приза превышает 4 000 (четыре тысячи) рублей,
						Организатор и Победитель дополнительно согласовывают детали уплаты
						всех применимых налогов в отношении такого приза в случаях,
						установленных действующим законодательством Российской Федерации; в
						случае если Организатору и Победителю не удастся прийти к соглашению
						по указанному вопросу, Организатор оставляет за собой право
						распорядиться призом по своему усмотрению, в том числе передать его
						другому Участнику Акции.
					</p>
					<p>3. Порядок участия в Акции</p>
					<p>
						3.1. Для участия в Акции Участнику необходимо загрузить Работу,
						созданную собственноручно Участником Акции в срок проведения Акции в
						соответствии с требованиями задания соответствующего конкурса, в
						специальную форму на сайте www.kanal-o.ru. Работы присылаются без
						знаков копирайта (©) и других обозначений авторства, без текстовых
						комментариев внутри Работы.
					</p>
					<p>
						3.1.1. Аккаунт (профиль) Участника в социальной сети должен быть
						открытым на период проведения Акции и должен содержать достоверную
						информацию об Участнике (например, фамилия, имя, возраст, место
						жительства и т.п.). Данная информация нужна исключительно для
						использования Организатором Акции в рамках его проведения Акции и
						для связи с Участником.
					</p>
					<p>
						3.2. Количество Работ, которое может выложить один Участник, не
						ограничено.
					</p>
					<p>
						3.3. Все другие способы отправки Работ, помимо указанного выше
						(телеграммой, факсом и др.), не рассматриваются.
					</p>
					<p>
						3.4. Выкладывая Работу, Участник соглашается с условиями Правил
						(совершает акцепт).
					</p>
					<p>3.5. Все Участники обязуются выполнять условия Правил.</p>
					<p>
						3.6. К участию в проекте НЕ допускаются Работы, не отвечающие
						техническим требованиям к Работе (технический брак), содержащие
						плагиат, информацию порнографического/ эротического содержания,
						рекламные, пропагандирующие насилие или содержащие сцены насилия,
						оскорбляющие религиозные чувства, демонстрирующие либо призывающие к
						нарушению общепринятых норм морали, или нарушающие личное
						достоинство, честь человека (людей), либо затрагивающие религиозные
						или национальные культурные ценности, выражающие агрессию,
						пропагандирующие экстремизм, насилие, потребление наркотиков,
						алкогольных напитков и т. п. и/или иным образом нарушающие
						требования Правил и/или законодательство РФ.
					</p>
					<p>4. Предоставление прав</p>
					<p>
						4.1. Выкладывая или присылая Работу Участник соглашается с условиями
						Правил (совершает акцепт), соглашаясь со всеми положениями Правил, а
						также гарантирует, что:
					</p>
					<ul>
						<li>
							он обладает исключительным правом в полном объеме на Работу,
							отправляемую для участия в Акции, и не передал данное
							исключительное право третьим лицам и/или не обременил каким-либо
							образом;
						</li>
						<li>
							Работа не нарушает законные права третьих лиц, включая, но не
							ограничиваясь, вещные права, интеллектуальные права, личные,
							гражданские, договорные и иные права, возникающие из любого вида
							сделок, нормативно-правовых и иных актов, судебных решений и иных
							оснований, а также не наносит ущерба чести, достоинству и/или
							деловой репутации третьих лиц и не содержит никаких незаконных
							и/или запрещенных к обнародованию материалов, а также, что от всех
							лиц, чьи изображения используются в Работе, получены разрешения на
							такое использование;
						</li>
						<li>
							он действует добросовестно и не злоупотребляет правом, в связи с
							чем факт отправки Работы путём размещения на сайте www.kanal-o.ru
							лицом, не имеющим на то права, но которое фактически приняло
							Правила таким образом, не является основанием для признания такого
							согласия Участники недействительным, а использование Работы
							контрафактным.
						</li>
					</ul>
					<p>
						Организатор оставляет за собой право в любое время в ходе проведения
						Акции и после её окончания отстранить от участия в Акции Участников,
						нарушивших указанные положения, а также Участников, использовавших
						мошеннические и/или недобросовестные методы участия в Акции.
					</p>
					<p>
						4.2. Участник предоставляет Организатору право на неоднократное
						использование Работ, их обнародование и дальнейшее их использование
						без выплаты вознаграждения, на территории стран всего мира, с даты
						начала проведения Акции и до окончания срока действия
						исключительного права на Работы, полностью или фрагментарно любыми
						не противоречащими действующему законодательству РФ способами
						включая, но не ограничиваясь, следующими способами в рамках эфира
						телеканала «О!» и иных телеканалов производства Организатора Акции,
						на сайте телеканала (www.kanal-o.ru), а также в социальных сетях
						«Одноклассники» (ok.ru), «Вконтакте» (vk.com), «Фейсбук»
						(facebook.com), «Инстаграм» (Instagram.com): воспроизводить,
						распространять, доводить до всеобщего сведения, в том числе, в сети
						Интернет, сообщать в эфир, по спутнику и по кабелю, полностью или
						фрагментарно, в цвете или нет; перерабатывать, а также использовать
						Работы, включая, но не ограничиваясь, любыми иными способами,
						указанными в ст. 1270 ГК РФ, в том числе в целях анонсирования и
						рекламирования Акции и/или телеканалов производства Организатора в
						составе любых средств массовой информации, а также любыми способами
						наружной рекламы, с правом передавать указанные права на
						использование третьим лицам.
					</p>
					<p>
						4.3. При этом, автор Работы (Участник) имеет право использовать
						Работу, участвующую в Акции, в личных целях.
					</p>
					<p>
						4.4. Права на Работу, указанные в пункте 4.2 настоящих Правил,
						передаются Организатору с момента публикации на сайте
						www.kanal-o.ru. Правил или отправки Работы по почте Организатору.
						Организатор вправе использовать Работу Участника без каких-либо
						ограничений, в том числе передавать её третьим лицам.
					</p>
					<p>
						4.5. Участник принимает на себя ответственность за содержание
						Работы, в связи с чем обязуется в случае возникновения требований,
						претензий и/или исков со стороны третьих лиц, оспаривающих обладание
						исключительным и/или иными правами на Работы либо возникших в связи
						с её использованием, урегулировать эти требования, претензии и/или
						иски своими силами и за свой счет, а в случае возникновения у
						Организатора убытков, возместить их Организатору в полном объеме.
					</p>
					<p>
						4.6. Ответственность за нарушения прав третьих лиц (в том числе,
						авторских, смежных и иных прав третьих лиц), допущенных Участниками
						в связи с предоставлением на Акцию Работ, несут сами Участники.
						Организатор не несет какой-либо имущественной или неимущественной
						ответственности ни перед Участником, ни перед третьими лицами, права
						которых нарушены.
					</p>
					<p>
						4.7. Принимая участие в Акции, Участники соглашаются с тем, что их
						имена и фамилии, Работы, изображения, а также имена, фамилии могут
						быть обнародованы, в том числе в сети Интернет, без дополнительного
						согласия Участников и без уплаты им вознаграждения. Организатор
						оставляет за собой право использовать Работы с целью популяризации
						Акции, а также телеканалов Организатора путем размещения на сайтах
						Организатора, хранения и использования в печатной и другой рекламной
						продукции.
					</p>
					<p>
						4.8. Организатор вправе использовать представленные Работы без
						указания имени Участника (анонимно).
					</p>
					<p>
						4.9. Предоставленные в соответствии с настоящим разделом правил
						Акции заявления (согласия) действуют в течение срока действия
						авторского права на Работы на территории стран всего мира.
					</p>
					<p>5. Заключительные положения</p>
					<p>
						5.1. Организатор оставляет за собой право подвергнуть любой проверке
						авторство и оригинальность Работ.
					</p>
					<p>
						5.2. Участники разрешают Организатору осуществлять обработку и
						хранение персональных данных таких Участников, полученных в рамках
						проведения Акции, в течение срока действия авторского права на
						Работы для целей участия в Акции. Участники Акции понимают и
						соглашаются с тем, что дают свое согласие на обработку персональных
						данных Участников в соответствии с требованиями Федерального закона
						от 27 июля 2006 г. № 152-ФЗ «О персональных данных». Контактная
						информация — телефон, адрес, электронная почта Участников, а также
						другие данные Участников могут быть использованы Организатором
						только для целей участия в Акции, в других целях — только с согласия
						Участников.
					</p>
					<p>
						5.3. Организатор имеет право отказать Участнику в признании его
						одним из Победителей Акции, если Участник предоставил о себе
						неверную информацию, предоставил ее несвоевременно или каким-либо
						другим образом нарушил Правила проведения Акции.
					</p>
					<p>
						5.4. Организатор оставляет за собой право на исключение каких-либо
						Работ Участников из Акции без объяснения причин, а также оставляет
						право отказаться от проведения Акции в любое время по своему
						усмотрению.
					</p>
					<p>
						5.5. Организатор не несет ответственности за неполучение от
						Участника необходимых сведений, в том числе по вине организаций
						связи, за технические проблемы и/или мошенничества в сети Интернет
						и/или каналов связи, используемых при проведении Акции, а также за
						невозможность осуществления связи с Участником из-за указанных
						неверных или неактуальных контактных данных.
					</p>
					<p>
						5.6. Все спорные вопросы, касающиеся Акции, регулируются в
						соответствии с действующим законодательством РФ.
					</p>
					<p>
						5.7. Участник соглашается с тем, что все обязанности по уплате
						налогов в связи с Акцией он несет самостоятельно.
					</p>
					<p>
						5.8. Участник обязан сохранять копии всех электронных и почтовых
						отправлений в связи с участием в Акции. В случае предъявления
						Участником претензии Организатору, основанной на переписке в
						электронной или письменной форме между Участником и представителем
						Организатора, Участник обязан предоставить Организатору копии
						соответствующей корреспонденции. Организатор, в свою очередь, вправе
						хранить соответствующую переписку, а также осуществлять запись
						телефонных разговоров с Участником в целях разрешения спорных
						вопросов.
					</p>
				</Text>
			</Div>
		</ModalPage>
	);
};

class App extends React.Component {
	constructor() {
		super();
		this.mode = "standalone";
		if (window.location.search.indexOf("?mode=web") >= 0) {
			this.mode = "web";
		} else if (window.location.search.indexOf("?mode=vk") >= 0) {
			this.mode = "vk";
		}

		let activePanelVal = "panelWelcome";

		// if (typeof window.localStorage !== "undefined") {
		// 	if (
		// 		localStorage.ts &&
		// 		parseInt(localStorage.ts) + 60 * 60 > Math.floor(Date.now() / 1000)
		// 	) {
		// 		activePanelVal = "panelAlready";
		// 	}
		// }

		if (this.mode === "web") {
			activePanelVal = "panelLogin";
		} else if (this.mode === "vk" || this.mode === "standalone") {
			activePanelVal = "panelWelcome";
		}

		console.log(this.mode);
		console.log(activePanelVal);

		this.isChecked = true;

		// STATE
		this.state = {
			token: "",
			activeModal: null,
			modalHistory: [],
			popout: null,
			activePanel: activePanelVal,
			prevPanel: null,
			taleTitle: "",
			taleText: "",
		};

		// Auth
		this.app_id = "7859551";

		// Mini-app
		this.app_id_miniapp = "7860368";
		this.accessToken = null;
		this.user = {};
		this.data = {};

		// SHARE
		this.shareUrlWeb = "https://www.kanal-o.ru/skazki?utm_source=app";
		this.shareTitleWeb =
			"Расскажи нам свою сказку — «О! Сказки» — только на канале «О!»";
		this.shareTitleVK =
			"Расскажи нам свою сказку — «О! Сказки» — только на канале «О!»";
		this.shareUrlVK = `https://vk.com/app${this.app_id_miniapp}`;

		// FUNC
		this.VKWallPost = this.VKWallPost.bind(this);
		this.sendGA = this.sendGA.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeAcception = this.onChangeAcception.bind(this);
		this.formSend = this.formSend.bind(this);
		this.openPopout = this.openPopout.bind(this);
		this.closePopout = this.closePopout.bind(this);
		this.modalBack = () => {
			this.setActiveModal(
				this.state.modalHistory[this.state.modalHistory.length - 2]
			);
		};

		this.initVK();
	}

	// VK BRIDGE
	initVK() {
		bridge.subscribe((e) => {
			switch (e.detail.type) {
				case "VKWebAppGetUserInfoResult":
					this.setState({ user: e.detail.data });
					console.log("getUser");
					break;
				case "VKWebAppAccessTokenReceived":
					this.setState({
						token: e.detail.data.access_token,
					});
					break;
				case "VKWebAppShowWallPostBoxResult":
					console.log("wall post");
					console.log(e.data);
					break;
				case "VKWebAppShowWallPostBoxFailed":
					console.log("wall post filed");
					console.log(e.data);
					break;
				case "VKWebAppAccessTokenFailed":
					console.log("token filed");
					break;
				case "VKWebAppCallAPIMethodResult":
					console.log(e.detail);
					break;
				case "VKWebAppCallAPIMethodFailed":
					console.log(e.detail.data);
					break;
				default:
					console.log(e.detail.type);
			}
		});
		setTimeout(() => {
			bridge.send("VKWebAppGetUserInfo", {});
			bridge.send("VKWebAppGetAuthToken", {
				app_id: this.app_id_miniapp,
				scope: "wall",
			});
		}, 500);
	}

	// GA
	sendGA(step) {
		ReactGA.initialize("UA-1391273-11");
		ReactGA.pageview(window.location.pathname + window.location.search);
		ReactGA.event({
			category: "O-Tales-App",
			action: step,
			label: this.mode,
		});
	}

	// PANELS
	panelChangeHandler(id) {
		const prevPanel = this.state.activePanel;
		if (prevPanel) {
			this.setState({ activePanel: id, prevPanel: prevPanel });
		} else {
			this.setState({ activePanel: id });
		}
		const msg = `Start ${id} panel`;
		this.sendGA(msg);
	}

	oneMoreTale() {
		this.setState({
			taleTitle: "",
			taleText: "",
			popout: null,
		});
		this.panelChangeHandler("panelTale");
	}

	endApp() {
		this.setState({
			taleTitle: "",
			taleText: "",
			popout: null,
		});
		this.panelChangeHandler("panelWelcome");
	}

	// POPUP
	openPopout(type, title, text) {
		if (type === "error") {
			this.setState({
				popout: (
					<Alert
						actions={[
							{
								title: "Закрыть",
								autoclose: true,
								mode: "cancel",
							},
						]}
						actionsLayout="horizontal"
						onClose={this.closePopout}
						header={title}
						text={text}
					/>
				),
			});
		} else if (type === "loading") {
			this.setState({
				popout: <ScreenSpinner />,
			});
		}
	}

	closePopout() {
		this.setState({ popout: null });
	}

	// MODAL
	setActiveModal(activeModal) {
		activeModal = activeModal || null;
		let modalHistory = this.state.modalHistory
			? [...this.state.modalHistory]
			: [];

		if (activeModal === null) {
			modalHistory = [];
		} else if (modalHistory.indexOf(activeModal) !== -1) {
			modalHistory = modalHistory.splice(
				0,
				modalHistory.indexOf(activeModal) + 1
			);
		} else {
			modalHistory.push(activeModal);
		}

		this.setState({
			activeModal,
			modalHistory,
		});
	}

	// FORM
	onChange(e) {
		const { name, value } = e.currentTarget;
		this.setState({ [name]: value });
	}

	onChangeAcception(e) {
		this.isChecked = e.target.checked;
	}

	formSend(text) {
		if (text.length > 0 && this.isChecked) {
			// SEND
			let isEmpty = true;
			if (this.state.taleText) {
				isEmpty = false;
			}

			if (!isEmpty) {
				this.data["text"] = this.state.taleText;
				this.data["vkid"] = this.user.id;
				this.data["name"] = `${this.user.first_name} ${this.user.last_name}`;
				this.data["ts"] = Math.floor(Date.now() / 1000);
				this.data["from"] = this.mode;
				if (typeof window.localStorage === "undefined") {
					this.data["ls"] = "1";
				}

				console.log(this.data);

				if (this.data.vkid) {
				}

				// Iframe data to parent window
				//window.parent.postMessage(this.data, '*')

				this.openPopout("loading");
				this.sendGA("Send tale form");
				fetch("https://www.kanal-o.ru/api/o-skazki", {
					method: "POST",
					headers: {
						Accept: "application/json, text/plain, */*",
						"Content-Type": "application/json",
					},
					body: JSON.stringify(this.data),
				})
					.then((res) => {
						if (!res.ok) {
							return {};
						} else {
							return res.json();
						}
					})
					.then((data) => {
						if (data.result && data.result === "ok") {
							if (typeof window.localStorage !== "undefined") {
								localStorage.id = this.data.vkid;
								localStorage.ts = this.data.ts;
							}
							this.closePopout();
							this.panelChangeHandler("panelFinish");
							this.sendGA("Send tale form OK");
						} else {
							this.closePopout();
							this.openPopout(
								"error",
								"Ошибка отправки данных",
								"Попробуйте еще раз."
							);
							this.sendGA("Send tale form ERROR");
						}
					});
			}
		} else {
			this.closePopout();
			this.openPopout("error", "Форма не заполнена");
		}
	}

	handleVKResponse = async (data) => {
		try {
			// const res = await data;
			// console.log(data);
			this.user["id"] = await data.session.user.id;
			this.user["first_name"] = await data.session.user.first_name;
			this.user["last_name"] = await data.session.user.last_name;
		} catch (err) {
			new Error(err);
		}

		this.panelChangeHandler("panelTale");
	};

	// SHARE
	async VKWallPost() {
		bridge.send("VKWebAppShowWallPostBox", {
			message: this.shareTitleVK,
			attachments: this.shareUrlVK,
		});
	}

	render() {
		const modal = (
			<ModalRoot activeModal={this.state.activeModal} onClose={this.modalBack}>
				<DynamicModalPageRules
					id="dynamic"
					onClose={this.modalBack}
					dynamicContentHeight
				/>
			</ModalRoot>
		);

		return (
			<AppRoot>
				<View
					popout={this.state.popout}
					activePanel={this.state.activePanel}
					modal={modal}>
					<Panel id="panelWelcome">
						<PanelHeader>ПРИВЕТ</PanelHeader>
						<Div>
							<Button
								size="l"
								stretched
								onClick={() => this.panelChangeHandler("panelTale")}>
								Написать свою сказку
							</Button>
						</Div>
					</Panel>

					<Panel id="panelLogin">
						<PanelHeader>ПРИВЕТ</PanelHeader>
						<Div>
							<VKAuth
								apiId={this.app_id}
								callback={this.handleVKResponse}
								stretched
								size="l">
								<div style={{ display: "flex", alignItems: "center" }}>
									<span style={{ marginRight: "4px" }}>Войти через</span>{" "}
									<Icon36LogoVk height={26} />
								</div>
							</VKAuth>
						</Div>
					</Panel>

					<Panel id="panelTale">
						<PanelHeader>СКАЗКА</PanelHeader>
						<FormItem top="ВАША СКАЗКА">
							<Textarea
								style={{ height: 200 }}
								name="taleText"
								value={this.state.taleText}
								placeholder="В некотором царстве..."
								onChange={this.onChange}
							/>
						</FormItem>
						<FormItem>
							<Checkbox defaultChecked onChange={this.onChangeAcception}>
								Я cогласен(а) с правилами конкурса
							</Checkbox>
						</FormItem>
						<Div>
							<Button
								stretched
								mode="secondary"
								size="m"
								onClick={() => this.setActiveModal("dynamic")}>
								Правила конкурса
							</Button>
						</Div>
						<Div>
							<Button
								stretched
								size="l"
								onClick={this.formSend.bind(this, this.state.taleText)}>
								Отправить
							</Button>
						</Div>
					</Panel>

					<Panel id="panelAlready">
						<PanelHeader>Возвращайтесь чуть позже</PanelHeader>
					</Panel>

					<Panel id="panelFinish">
						<PanelHeader>ПОДЕЛИТЬСЯ</PanelHeader>
						<Div>
							{this.mode === "vk" && (
								<Button
									className="header__start"
									onClick={this.VKWallPost}
									level="primary"
									before={<Icon24Share />}
									size="l"
									style={{ marginBottom: 10 }}>
									Поделиться
								</Button>
							)}
							{this.mode !== "vk" && (
								<VKShareButton
									url={this.shareUrlWeb}
									quote={this.shareTitleWeb}
									style={{ margin: 6 }}>
									<VKIcon size={48} round />
								</VKShareButton>
							)}
							{this.mode !== "vk" && (
								<OKShareButton
									url={this.shareUrlWeb}
									quote={this.shareTitleWeb}
									style={{ margin: 6 }}>
									<OKIcon size={48} round />
								</OKShareButton>
							)}
							{this.mode !== "vk" && (
								<FacebookShareButton
									url={this.shareUrlWeb}
									quote={this.shareTitleWeb}
									style={{ margin: 6 }}>
									<FacebookIcon size={48} round />
								</FacebookShareButton>
							)}
						</Div>
						<Div>
							{/*<Button stretched size="l" onClick={() => this.endApp()}>*/}
							{/*	Вернуться назад*/}
							{/*</Button>*/}
							<Spacing size={8} />
							<Button stretched size="l" onClick={() => this.oneMoreTale()}>
								Написать еще одну сказку
							</Button>
						</Div>
					</Panel>

					<DynamicModalPageRules
						id="dynamic"
						onClose={this.modalBack}
						dynamicContentHeight
					/>
				</View>
			</AppRoot>
		);
	}
}

export default App;
